import React from 'react'
import { Button } from './Button'
import { Link } from 'react-router-dom'

export const HeroBlock = ({ title, desc, bg, btn, thread, className, mainPage }) => {
  let threadLink = "/"

  if (thread === "проекты")
    threadLink = "/"

  if (thread === "услуги")
    threadLink = "/services"

  if (thread === "блог")
    threadLink = "/blog"


  if(mainPage){
    return (
      <section className={'hero ' + className} style={{ backgroundImage: 'url("' + bg + '")' }}>
        <div className='site-container'>
          {thread !== undefined ? <Link to={threadLink} className='hero-block_page'>/{thread}</Link> : <div className='hero-block_page' style={{height: "30px"}}> </div>}
          <div className='hero-block_text'>
            <h1>{title}</h1>
            <div className="desc-container">
              <p>
                {desc}
              </p>
              {btn !== undefined && btn}
            </div>
          </div>
        </div>
        {bg !== undefined && <div className='hero-block_bg' style={{opacity: "0.4"}} alt="" />}
      </section>
    )
  } else {
    return (
      <section className={'hero ' + className}>
        <div className='site-container'>
          {thread !== undefined ? <Link to={threadLink} className='hero-block_page'>/{thread}</Link> : <div className='hero-block_page' style={{height: "30px"}}> </div>}
          <div className='hero-block_text'>
            <h1>{title}</h1>
            <div className="desc-container">
              <p>
                {desc}
              </p>
              {btn !== undefined && btn}
            </div>
          </div>
        </div>
        {bg !== undefined && <div className='hero-block_bg' style={{ backgroundImage: 'url("' + bg + '")' }} alt="" />}
      </section>
    )
  }
}
