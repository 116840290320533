import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export const Card = ({title, imgsrc, link}) => {
  const [img, setImg] = useState(imgsrc)

  function setErr() {
    setImg('/images/broken-img.svg')
  }
  if (img==undefined){
    setErr()
  }
  return (
    <Link to={link} className="default-card">
      <span className="default-card_title">{title}</span>
      <img src={img} alt="" onError={setErr}/>
    </Link>
  )
}
